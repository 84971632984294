@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; /* Adjust this if necessary */
  min-height: 100vh;
  padding: 24px;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.confirm-button {
  position: fixed;
  bottom: 5vh; /* Adjust based on actual button height */
  left: 50%;
  transform: translateX(-50%); /* Centers the button */
  padding: 12px 24px;
  background-color: #4CAF50; /* Example color */
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  z-index: 1000; /* Ensures it floats above other content */
}

/* Class for a react-grid-container that can fill the whole remainder of the page */
.layout {
  display: flex;
  height: 80%;
  width: 100%;
}

.grid-layout {
  min-width: 90vw;
  min-height: 100vh;
}

.centered-div {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80vw;
}


body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}
